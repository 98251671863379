import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/styleConstants";

export default function Dropdown({ style, children }) {
  return (
    <div className="inputGroup">
      <label>Filing status</label>
      <Select style={style}>{children}</Select>
    </div>
  );
}

const Select = styled.select`
  ${(props) => `
  height: ${props.style?.height ? props.style?.height : "60px"};
  width: ${props.style?.width ? props.style?.width : "100%"};
  outline: none;
  background-color: ${
    props.style?.backgroundColor ? props.style?.backgroundColor : Colors.white
  };
  color: ${props.style?.color ? props.style?.color : Colors.almostBlack};
  padding: ${props.style?.padding ? props.style?.padding : "5px 10px"};
  margin: ${props.style?.margin ? props.style?.margin : 0};
  border: ${props.style?.border ? props.style?.border : "none"};
  border-radius: ${
    props.style?.borderRadius ? props.style?.borderRadius : "5px"
  };
`}
`;
