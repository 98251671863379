/* eslint-disable no-underscore-dangle */
import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/styleConstants";
import { USER_MESSAGE_TYPE } from "../../utils/commonConst";
import Image from "./Image";

const MESSAGE_TYPE_ICON_MAPPING = {
  [USER_MESSAGE_TYPE.ERROR]: "common/warning_red.png",
  [USER_MESSAGE_TYPE.WARNING]: "common/ic-warning.png",
};

function RequiredMessage(props) {
  const {
    message,
    size = "default",
    type = USER_MESSAGE_TYPE.ERROR,
  } = props ?? {};

  return (
    <RequiredMessageWrapper>
      {MESSAGE_TYPE_ICON_MAPPING[type] != null && (
        <Image
          src={MESSAGE_TYPE_ICON_MAPPING[type]}
          style={{ width: "16px", height: "16px" }}
        />
      )}
      <Message size={size} type={type}>
        {message ?? "This is a required field."}
      </Message>
    </RequiredMessageWrapper>
  );
}

export default RequiredMessage;

const RequiredMessageWrapper = styled.div`
  font-style: normal;
  margin: 12px 0px;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const RequiredIcon = styled.img`
  ${(props) => (props.size === "medium" ? "width: 24px;" : "width: 16px;")}
`;

const Message = styled.div`
  color: ${(props) =>
    props.type === USER_MESSAGE_TYPE.CONFIRMATION
      ? Colors.green
      : props.type === USER_MESSAGE_TYPE.ERROR
      ? Colors.errorRed
      : Colors.almostBlack};
  margin-left: 0.5rem;
  ${(props) =>
    props.size === "medium"
      ? `
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  `
      : `  font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      font-style: normal;`}
`;
