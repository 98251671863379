import React from "react";
import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";

import RequiredMessage from "./RequiredMessage";
import { REQUIRED_MESSAGE_PREFIX } from "../../utils/commonConst";
import HelperText from "./HelperText";
import { isNonEmpty } from "../../utils/commonUtil";

function InputText(props) {
  const {
    name,
    value,
    isRequired = false,
    onChange,
    placeholder,
    label,
    helperText,
    className,
    style,
    width = "100%",
    height = "56px",
    showValidation = false,
    type = "text",
  } = props;
  const _onChange = (event) => {
    onChange(event.target.value);
    event.stopPropagation();
  };
  return (
    <InputTextWrapper className={className} style={style}>
      <InputTextGroup value={value}>
        <InputLabel>{label}</InputLabel>
        <InputTextComp
          type={type}
          label={label}
          value={value}
          required={isRequired}
          placeholder={placeholder ?? "Type your answer here"}
          onChange={_onChange}
          size={width}
          height={height}
        />
      </InputTextGroup>
      {isNonEmpty(helperText) && <HelperText>{helperText}</HelperText>}
      {isRequired && showValidation && (
        <RequiredMessage
          message={
            isNonEmpty(name) ? `${REQUIRED_MESSAGE_PREFIX} ${name}` : null
          }
        />
      )}
    </InputTextWrapper>
  );
}

export default InputText;

const InputTextWrapper = styled.div.attrs({
  className: "fs-exclude",
})``;

const InputTextComp = styled.input`
  font-style: normal;
  font-weight: ${FontWeights.normal};
  font-size: ${FontSizes.md};
  line-height: 30px;
  height: ${(props) => props.height};
  width: ${(props) => props.size};
  font-family: ${FontFamilies.base};
  color: ${Colors.almostBlack};
  :focus::placeholder {
    opacity: 0;
  }

  :not(:placeholder-shown) {
    padding: 16px 16px ${(props) => (isNonEmpty(props.label) ? "8px" : "16px")}
      16px;
  }

  @media only screen and (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`;
// const InputTextHelperText = styled.p`
//   font-style: normal;
//   font-weight: ${FontWeights.medium};
//   font-size: ${FontSizes.sm};
//   color: ${props => props.theme.primaryTextColor};
//   margin: 5px 0px;
//
//   @media only screen and (max-width: ${Breakpoints.sm}) {
//     font-size: ${FontSizes.xs};
//     line-height: 16px;
//     flex: none;
//     order: 1;
//     align-self: stretch;
//     flex-grow: 0;
//     margin: 4px 0px;
//   }
// `;

const InputLabel = styled.label`
  font-weight: ${FontWeights.bolder};
  font-size: ${FontSizes.xs};
  color: ${Colors.almostBlack};
  font-family: ${FontFamilies.base};
`;

const InputTextGroup = styled.div`
  position: relative;
  label {
    position: absolute;
    top: 4px;
    left: 17px;
    opacity: ${(props) => (isNonEmpty(props.value) ? 1 : 0)};
    transition: opacity 0.2s;
  }
  :focus-within label {
    opacity: 1;
  }
`;
