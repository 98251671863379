import React, { useEffect, useState } from "react";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  FontWeights,
  Spacing,
} from "../../styles/styleConstants";
import {
  HEADER_LEVEL,
  INPUT_NUMBER_TYPE,
  USER_MESSAGE_TYPE,
} from "../../utils/commonConst";
import Dropdown from "../common/Dropdown";
import InputText from "../common/InputText";
import InputField from "../common/InputField";
import { submitEquityAIQuestion } from "../../utils/apiUtils";
import InputDropDown from "../common/InputDropDown";
import InputTextArea from "../common/InputTextArea";
import Image from "../common/Image";
import {
  isEmailValid,
  isEmpty,
  isNonEmpty,
  navigateTo,
  scrollTo,
} from "../../utils/commonUtil";
import InputNumber from "../common/InputNumber";
import RequiredMessage from "../common/RequiredMessage";

const STOCK_OPTIONS = [
  {
    label: "NSO",
    value: "NSO",
  },
  {
    label: "ISO",
    value: "ISO",
  },
  {
    label: "RSU",
    value: "RSU",
  },
  {
    label: "ESPP",
    value: "ESPP",
  },
];

export default function EquityAIDemo() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [aiResponse, setAiResponse] = useState("");
  const [question, setQuestion] = useState("");
  const [approxSavings, setApproxSavings] = useState(null);
  const [email, setEmail] = useState("");
  const [householdIncome, setHouseholdIncome] = useState(null);
  const [stockOption, setStockOption] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setAiResponse("");
    if (isSubmitted === true) {
      if (isNaN(Number(householdIncome)) || isNaN(Number(approxSavings))) {
        setErrorMsg("Please enter valid income and savings information");
      } else {
        scrollTo("#ai-equity-q-resp");
        submitEquityAIQuestion({
          question,
          income: Number(householdIncome),
          saving: Number(approxSavings),
          stockOption,
          email,
        })
          .then((response) => {
            setAiResponse(response?.data?.answer);
          })
          .catch((exception) => {
            setErrorMsg(
              "This is taking longer than expected. Please try again."
            );
          });
      }
    }
  }, [isSubmitted]);

  useEffect(() => {
    setIsSubmitted(false);
    setErrorMsg("");
  }, [question, approxSavings, householdIncome, stockOption, email]);

  return (
    <AIDemoWrapper>
      <Container className="equity-container">
        <LeftSide>
          <StockEdition>
            <HeaderText level={HEADER_LEVEL.LEVEL_7}>
              COMPANY STOCK EDITION 🚀
            </HeaderText>
          </StockEdition>
          <HeaderText
            level={HEADER_LEVEL.LEVEL_1}
            style={{ color: Colors.white, margin: `${Spacing.md} 0` }}
          >
            <TextHighlight>AI-powered</TextHighlight> financial advice demo
          </HeaderText>
          <HeaderText
            level={HEADER_LEVEL.BODY_COPY}
            style={{ color: Colors.white }}
          >
            Uprise is pioneering the use of AI and machine learning in the field
            of wealth management. This demo is trained on company equity topics,
            including RSUs, ESPPs, stock options, 83(b)s, and more.{" "}
          </HeaderText>
          {/* <TextAreaField
            placeholder="Ask us anything about company stock..."
            rows={4}
          /> */}
          <InputFormWrapper>
            <InputTextArea
              isRequired
              showValidation={false}
              placeholder="Ask us anything about company stock..."
              onChange={(value) => setQuestion(value)}
              rows={5}
              style={{ width: "100%" }}
              value={question}
            />
            <HeaderText
              level={HEADER_LEVEL.BODY_COPY}
              style={{ color: Colors.white }}
            >
              Optional for more specific responses
            </HeaderText>
          </InputFormWrapper>
          <Row className="ai-equity-fields">
            <Col>
              <ColumnContaier>
                {/* <InputNumber
            name={name}
            value={inputValue}
            onChange={_onChange}
            isRequired={isRequired}
            minValue={minValue}
            maxValue={maxValue}
            numberType={numberType}
            isDecimal={isDecimal}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            width={width}
            showValidation={showValidation}
          /> */}
                <InputNumber
                  label="Household income"
                  type="text"
                  placeholder="  Household income"
                  onChange={(value) => {
                    setHouseholdIncome(value);
                  }}
                  name="householdIncome"
                  value={householdIncome}
                  numberType={INPUT_NUMBER_TYPE.CURRENCY}
                  isDecimal={true}
                  width="100%"
                  style={{
                    width: "100%",
                  }}
                />
                <InputDropDown
                  value={stockOption}
                  options={STOCK_OPTIONS}
                  onChange={(value) => setStockOption(value)}
                  isRequired={false}
                  showValidation={false}
                  placeholder="Type of company equity (if applies)"
                  selectStyle={{ width: "100%" }}
                  style={{
                    width: "100%",
                  }}
                />
              </ColumnContaier>
            </Col>
            <Col>
              <ColumnContaier>
                <InputNumber
                  label="Approximate savings"
                  type="text"
                  placeholder="  Approximate savings"
                  onChange={(value) => setApproxSavings(value)}
                  name="approxSavings"
                  value={approxSavings}
                  width="100%"
                  numberType={INPUT_NUMBER_TYPE.CURRENCY}
                  isDecimal={true}
                  style={{
                    width: "100%",
                  }}
                />
                <InputText
                  label="Email"
                  placeholder="  Email (required)"
                  type="email"
                  onChange={(value) => setEmail(value)}
                  name="email"
                  value={email}
                  style={{ width: "100%" }}
                />
              </ColumnContaier>
            </Col>
          </Row>
          <ButtonGroup id="ai-equity-q-resp" style={{ padding: "24px 0px" }}>
            <CtaButton
              style={{ minHeight: "48px" }}
              disabled={
                isSubmitted || !isEmailValid(email) || isEmpty(question)
              }
              onClick={(evt) => {
                setIsSubmitted(true);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
            >
              Submit
            </CtaButton>
            <CtaButton
              style={{ marginLeft: Spacing.sm, minHeight: "48px" }}
              onClick={(evt) => {
                setQuestion("");
                setEmail("");
                setHouseholdIncome("");
                setApproxSavings("");
                setStockOption("");
                setIsSubmitted(false);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="secondary"
            >
              Clear
            </CtaButton>
          </ButtonGroup>
        </LeftSide>
        {isSubmitted === true && (
          <RightSide>
            <ResponseWrapper>
              <HeaderText level={HEADER_LEVEL.LEVEL_2}>Response</HeaderText>
              <HighlightedText>
                Note that responses generated by AI should be used for demo
                purposes only and DO NOT constitute investment, tax, or legal
                advice.
              </HighlightedText>
              <HeaderText
                level={HEADER_LEVEL.BODY_COPY}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {aiResponse}
              </HeaderText>
              {isNonEmpty(errorMsg) && (
                <RequiredMessage
                  type={USER_MESSAGE_TYPE.WARNING}
                  message={errorMsg}
                />
              )}
              {isEmpty(aiResponse) && isEmpty(errorMsg) && (
                <Image
                  src="common/ai-response-loading.gif"
                  style={{
                    width: "42px",
                    height: "16px",
                  }}
                  imgStyle={{ objectFit: "contain" }}
                />
              )}
            </ResponseWrapper>
          </RightSide>
        )}
        <FooterDescription>
          <HeaderText
            level={HEADER_LEVEL.BODY_COPY}
            style={{ color: Colors.white, marginTop: Spacing.md }}
          >
            <i>
              Uprise’s website is limited to the dissemination of general
              information pertaining to its financial planning and advisory
              services (which expressly excludes advice regarding securities).
              This demo and any responses generated by AI should be used for
              testing purposes only and DO NOT constitute investment, tax, or
              legal advice.
            </i>
          </HeaderText>
        </FooterDescription>
      </Container>
    </AIDemoWrapper>
  );
}

// @media (max-width: 992px) {
//   flex-direction: column;
// }
// @media only screen and (max-width: ${Breakpoints.sm}) {
// }

const AIDemoWrapper = styled.div`
  background-color: ${Colors.tertiary};
  padding: ${Spacing.xxxl} 0;
  min-height: 100vh;

  .equity-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
    gap: 0px;
    @media (max-width: ${Breakpoints.sm}) {
      width: 100%;
    }
  }
`;

const StockEdition = styled.div`
  width: fit-content;
  padding: 2px ${Spacing.xs};
  background-color: ${Colors.blue30};
  border-radius: ${Spacing.sm};
`;

const TextHighlight = styled.span`
  position: relative;
  min-height: 65px;
  z-index: 1;
  margin-bottom: ${Spacing.sm};
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
    height: ${Spacing.xs};
    z-index: -1;
    background: linear-gradient(29.68deg, #3c5eff 0%, #00f4de 84.39%);
  }

  @media (max-width: 768px) {
    ::after {
      bottom: -4px;
      width: 185px;
    }
  }
`;

const LeftSide = styled.div`
  width: ${(props) => props.width};
  margin: auto;
  @media (max-width: 992px) {
    width: 100%;
  }

  .ai-equity-fields {
    @media only screen and (max-width: ${Breakpoints.sm}) {
      flex-direction: column;
      gap: 12px;
    }
  }
`;

// const Form = styled.form``;

const RightSide = styled.div`
  position: relative;
  display: ${(props) => props.display};
  background-color: ${Colors.blue60};
  border-radius: ${Spacing.xs};
  overflow: hidden;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: ${Spacing.xs};
    background: linear-gradient(29.68deg, #3c5eff 0%, #00f4de 84.39%);
  }
`;

const InputFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin: 40px 0px 24px;
`;
const ResponseWrapper = styled.div`
  padding: ${Spacing.xl};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HighlightedText = styled.p`
  font-family: ${FontFamilies.title};
  font-style: italic;
  font-weight: ${FontWeights.bold};
  line-height: none !important;
  background-color: ${Colors.sunShine};
  font-size: 16px;
  display: inline;
  margin: 0px 0px;
`;

const FooterDescription = styled.div`
  margin: auto;
`;

const NavigateToDiv = styled.div`
  display: inline;
`;

const ColumnContaier = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  @media only screen and (max-width: ${Breakpoints.sm}) {
    gap: 12px;
  }
`;
