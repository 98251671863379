import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/styleConstants";
import StarImage from "../common/StarImage";

export default function FeedbackCard(props) {
  const { feedbackText, feedbackBy, style } = props ?? {};
  return (
    <FeedbackCardWrapper style={style}>
      <FeedbackCardBody>
        <FeedbackText>{feedbackText}</FeedbackText>
        <FeedbackStarWrapper>
          <StarImage />
          <StarImage />
          <StarImage />
          <StarImage />
          <StarImage />
        </FeedbackStarWrapper>
        <FeedbackBy>{feedbackBy}</FeedbackBy>
      </FeedbackCardBody>
    </FeedbackCardWrapper>
  );
}

const FeedbackCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${Colors.almostBlack};
`;
const FeedbackCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 220px;
  height: 250px;
`;
const FeedbackStarWrapper = styled.div`
  flex-basis: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const FeedbackText = styled.div`
  display: flex;
  flex-basis: 80%;
  flex: 1;
`;
const FeedbackBy = styled.div`
  display: flex;
  flex-basis: 10%;
`;
