import React from "react";
import styled from "styled-components";

export default function InputField({
  style,
  placeholder,
  name,
  type = "text",
}) {
  return (
    <div className="inputGroup">
      <label>{placeholder}</label>
      <Input style={style} type={type} placeholder={placeholder} name={name} />
    </div>
  );
}

const Input = styled.input`
  ${(props) => `
    outline: none;
    border: ${props.style?.border ? props.style?.border : "none"};
    width:  ${props.style?.width ? props.style?.width : "100%"};
    padding:  ${props.style?.padding ? props.style?.padding : "5px 10px"};
    margin: ${props.style?.margin ? props.style?.margin : 0};
    border-radius: ${
      props.style?.borderRadius ? props.style?.borderRadius : "5px"
    };
  `}
`;
