import React from "react";
import styled from "styled-components";
import EquityAIDemo from "../../components/ai/EquityAIDemo";
import Faq from "../../components/common/faq";
import CurveTopBg from "../../images/home/gray-curve-top-bg.png";
import Footer from "../../components/common/footer";
import Header from "../../components/common/header";
import LogoBar from "../../components/common/logoBar";
import CustomerFeedback from "../../components/feedback/customerFeedback";
import Layout from "../../components/layouts/layout";
import AIHumanSecret from "../../components/partner/core/AIHumanSecret";
import PartnerContactUs from "../../components/partner/core/PartnerContactUs";
import { PressReleaseData, FaqData } from "../../data/homeData";
import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";
import { PAGE_PATH } from "../../utils/commonConst";

const HeadText = "Get premium, personalized financial advice on your platform.";
export default function AIDemoPage() {
  return (
    <Layout includeHeader={false} includeFooter={false}>
      <HeroWrapper>
        <Header />
      </HeroWrapper>
      <EquityAIDemo />
      <LogoBar content={PressReleaseData} style={{ padding: "32px 0px 0px" }} />
      <AIHumanSecret
        style={{ padding: "0px 0px 60px" }}
        headerText={
          "Get premium, personalized financial\u00A0advice\u00A0on\u00A0your\u00A0platform."
        }
        showSubText={false}
      />
      <CustomerFeedback />
      <PartnerContactUs
        contentStyle={{ background: `${Colors.sunShine}`, textAlign: "center" }}
        headerText={
          "Interested in AI-powered financial advice for your platform?"
        }
        buttonName={"Learn More"}
        buttonLinkTarget={PAGE_PATH.PARTNER}
      />
      {/* <FaqWrapper>
        <Faq content={FaqData} />
      </FaqWrapper> */}
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}

const FaqWrapper = styled.div`
  margin-top: ${Spacing.xxl};
`;

const HeroWrapper = styled.div`
  .HeroImage {
    width: 100%;
  }
`;

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;
