/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "../../styles/styleConstants";

import RequiredMessage from "./RequiredMessage";
import {
  INPUT_NUMBER_TYPE,
  REQUIRED_MESSAGE_PREFIX,
  VALIDATION_MESSAGE_VALID_VALUE,
} from "../../utils/commonConst";
import { isEmpty, isNonEmpty } from "../../utils/commonUtil";

const currencyRegEx = /^(?=.?\d)\d{0,10}(\.?\d{0,2})?$/;
const allowedDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const dotChar = ".";
function InputNumber(props) {
  const {
    name,
    value,
    isRequired = false,
    onChange,
    label,
    placeholder,
    width,
    helperText,
    minValue = -1000000000,
    maxValue = 1000000000,
    numberType = "regular",
    isDecimal = false,
    showValidation = false,
    style,
    className,
  } = props ?? {};
  const [isValidEntry, setIsValidEntry] = useState(true);
  const _onChange = (event) => {
    if (
      (!Number.isNaN(event.target.value) && isEmpty(event.target.value)) ||
      currencyRegEx.test(event.target.value)
    ) {
      onChange(event.target.value);
    }
  };
  return (
    <InputNumberWrapper style={style} className={className}>
      <InputNumberGroup value={value}>
        <InputNumberLabel>{label}</InputNumberLabel>
        <InputNumberPrefix numberType={numberType}>
          <InputNumberComp
            type="number"
            value={value}
            placeholder={placeholder ?? "Type your answer here"}
            onChange={_onChange}
            min={minValue}
            max={maxValue}
            step={isDecimal ? 0.01 : 1}
            size={width}
            onBlur={(evt) => {
              setIsValidEntry(evt.target.checkValidity());
            }}
            onKeyPress={(evt) => {
              const decimalOccurance =
                value != null ? value.split(".").length - 1 : 0;
              if (
                ((allowedDigits.includes(evt.key) || dotChar === evt.key) &&
                  decimalOccurance < 1) ||
                (decimalOccurance === 1 && allowedDigits.includes(evt.key))
              ) {
                return;
              }
              evt.preventDefault();
            }}
          />
        </InputNumberPrefix>
        <InputNumberHelperText>{helperText}</InputNumberHelperText>
        {isRequired && showValidation && isEmpty(value) && (
          <RequiredMessage
            message={
              isNonEmpty(name) ? `${REQUIRED_MESSAGE_PREFIX} ${name}` : null
            }
          />
        )}
        {!isValidEntry && isNonEmpty(value) && (
          <RequiredMessage message={VALIDATION_MESSAGE_VALID_VALUE} />
        )}
      </InputNumberGroup>
    </InputNumberWrapper>
  );
}

export default InputNumber;

const InputNumberWrapper = styled.div.attrs({
  className: "fs-exclude",
})``;

const InputNumberLabel = styled.label``;

const InputNumberGroup = styled.div`
  position: relative;
  label {
    position: absolute;
    top: 4px;
    left: 17px;
    opacity: ${(props) => (isNonEmpty(props.value) ? 1 : 0)};
    transition: opacity 0.2s;
    z-index: 1;
  }
  :focus-within label {
    opacity: 1;
  }
`;

const InputNumberPrefix = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.neutralDark};
  position: relative;
  input {
    ${(props) =>
      props.numberType !== INPUT_NUMBER_TYPE.REGULAR && "padding-left: 24px;"}
  }
  ${(props) =>
    props.numberType === INPUT_NUMBER_TYPE.CURRENCY &&
    `:before {
    position: absolute;
    top: 0;
    content: "$";
    left: 8px;
    margin-top: -3px;
    `}
    ${(props) =>
      props.numberType === INPUT_NUMBER_TYPE.PERCENTAGE &&
      `:before {
      position: absolute;
      top: 0;
      content: "%";
      right: 30px;
      margin-top: -3px;
      `}
  }
`;

const InputNumberComp = styled.input`
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  // width: ${(props) => props.size}px;
  width: 100%;
  height: 56px;

  :focus::placeholder {
    opacity: 0;
  }
  @media only screen and (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`;
const InputNumberHelperText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${Colors.almostBlack};
  margin: 5px 0px;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    font-size: 12px;
    line-height: 16px;
    align-self: stretch;
    margin: 4px 0px;
  }
`;
