/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RequiredMessage from "./RequiredMessage";
import {
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";
import downArrowBlack from "../../images/common/ic-chevron-down-black.png";
import { isEmpty, isMap, isNonEmpty } from "../../utils/commonUtil";

function InputDropDown(props) {
  const {
    value,
    options,
    onChange,
    isRequired = false,
    showValidation = false,
    placeholder = "Select an option",
    selectStyle = {},
    style = {},
  } = props ?? {};
  const [optionState, setOptionState] = useState();

  useEffect(() => {
    if (isEmpty(value) && isNonEmpty(options)) {
      setOptionState(options[0]);
    } else {
      setOptionState(value);
    }
  }, [value]);

  const inputOptions = () => {
    const result = options.map((optionObj, indx) => {
      return (
        <InputDDOption
          value={optionObj.value}
          key={`inp-drop-d-${indx}`}
          selected={optionState === optionObj.value}
        >
          {optionObj.label}
        </InputDDOption>
      );
    });
    result.unshift(
      <InputDDOption
        value=""
        key={`inp-drop-d-${result.length + 1}`}
        selected={optionState == null}
      >
        {placeholder}
      </InputDDOption>
    );
    return result;
  };
  return (
    <InputDropDownWrapper style={style}>
      <InputDDOptionsWrapper
        onChange={(evt) => {
          const selectedValue = evt.target.value;
          setOptionState(selectedValue);
          onChange(selectedValue);
          // evt.stopPropagation();
        }}
        style={selectStyle}
        defaultValue="select "
      >
        {inputOptions()}
      </InputDDOptionsWrapper>
      {isRequired && showValidation && (
        <RequiredMessage message="Please select at least one option above." />
      )}
    </InputDropDownWrapper>
  );
}

export default InputDropDown;

const InputDropDownWrapper = styled.div``;
const InputDDOptionsWrapper = styled.select`
  padding: 20px;
  width: 95%;
  border-bottom: 1px solid ${Colors.neutralMedium};
  height: 56px;
  font-style: normal;
  font-weight: ${FontWeights.medium};
  font-family: ${FontFamilies.base};
  font-size: ${FontSizes.md};
  line-height: 24px;
  color: ${Colors.almostBlack};
  padding: 16px;

  appearance: none;
  background-image: url(.${downArrowBlack});
  background-position: 95%;
  background-size: 25px;
  background-repeat: no-repeat;

  border: 1px solid ${Colors.neutralMedium};
  border-radius: 4px;
  background-color: ${Colors.white};

  after {
    top: 40%;
    right: 5px;
  }
`;
const InputDDOption = styled.option`
  font-family: ${FontFamilies.base};
`;
