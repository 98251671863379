import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from "../../styles/styleConstants";
import {
  HEADER_LEVEL,
  PRODUCTHUNT_URL,
  TRUSTPILOT_URL,
} from "../../utils/commonConst";
import {
  getAppUrlByModule,
  navigateTo,
  scrollTo,
} from "../../utils/commonUtil";
import CtaButton from "../common/CtaButton";
import HeaderText, { headerStyle } from "../common/HeaderText";
import HelperText from "../common/HelperText";
import Image from "../common/Image";
import StarImage from "../common/StarImage";
import UpriseLink, { LINK_TARGET } from "../common/UpriseLink";
import FeedbackCard from "./FeedbackCard";

export default function CustomerFeedback(props) {
  const {
    feedbackData,
    hidePricingButton = false,
    getStartedButtonLabel = "Get started",
  } = props ?? {};
  return (
    <>
      <Container>
        <CustomerFeedbackWrapper>
          <FeedbackHeader level={HEADER_LEVEL.LEVEL_1}>
            Trusted and loved by thousands
          </FeedbackHeader>
          <ProductHuntStar>
            <StarImage style={{ margin: "0px 4px" }} />
            <StarImage style={{ margin: "0px 4px" }} />
            <StarImage style={{ margin: "0px 4px" }} />
            <StarImage style={{ margin: "0px 4px" }} />
            <StarImage style={{ margin: "0px 4px" }} />
            <span style={{ fontWeight: "600" }}>4.8 stars</span>on{" "}
            <UpriseLink target={LINK_TARGET.BLANK} href={PRODUCTHUNT_URL}>
              Product Hunt
            </UpriseLink>{" "}
            and{" "}
            <UpriseLink target={LINK_TARGET.BLANK} href={TRUSTPILOT_URL}>
              TrustPilot
            </UpriseLink>
          </ProductHuntStar>
          <iframe
            className="testimonial"
            src="https://embed-v2.testimonial.to/carousel/all/uprise?theme=light&autoplay=on&showmore=off&one-row=on&same-height=on"
            frameborder="0"
            scrolling="no"
            width="100%"
          ></iframe>
          {/* <FeedbackCardWrapper>
            {feedbackData.map((feedback) => {
              return (
                <FeedbackCard
                  feedbackText={feedback.feedbackText}
                  feedbackBy={feedback.feedbackBy}
                />
              );
            })}
          </FeedbackCardWrapper> */}
          {hidePricingButton === false && (
            <CtaWrapper>
              <CtaButton
                onClick={(evt) => {
                  navigateTo(getAppUrlByModule());
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                ctaType="primary"
                style={{ marginRight: Spacing.lg }}
              >
                {getStartedButtonLabel}
              </CtaButton>
              <CtaButton
                onClick={(evt) => {
                  scrollTo("#pricing");
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                ctaType="secondary"
              >
                Explore pricing
              </CtaButton>
            </CtaWrapper>
          )}
        </CustomerFeedbackWrapper>
      </Container>
    </>
  );
}

const CustomerFeedbackWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: block;
  .testimonial {
    min-height: 72vh;
    max-height: 80vh;
    @media (max-width: ${Breakpoints.md}) {
      min-height: 80vh;
    }
  }
`;

const FeedbackHeader = styled.div`
  ${headerStyle[HEADER_LEVEL.LEVEL_1]}
`;

const ProductHuntStar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  margin: ${Spacing.lg} 0px ${Spacing.md};
  color: ${Colors.almostBlack};
  span,
  u {
    margin: auto 7px;
  }
`;

const FeedbackCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 48px 0px 0px;
  padding: 16px;
  gap: 24px;
  @media (max-width: ${Breakpoints.xl}) {
    justify-content: flex-start;
    overflow-x: scroll;
    padding: 8px;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px ${Spacing.md};
  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
    button:last-child {
      margin-top: ${Spacing.md};
    }
  }
`;
