import React from "react";
import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";

import RequiredMessage from "./RequiredMessage";
import { REQUIRED_MESSAGE_PREFIX } from "../../utils/commonConst";
import { isNonEmpty } from "../../utils/commonUtil";

function InputTextArea(props) {
  const {
    name,
    value,
    label,
    isRequired = false,
    onChange,
    onFocus,
    placeholder,
    showValidation = false,
    height = "127px",
    rows = 10,
    style,
    className,
  } = props ?? {};
  const _onChange = (event) => {
    onChange(event.target.value);
    event.stopPropagation();
  };

  const _onFocus = (event) => {
    if (onFocus != null) {
      onFocus(event.target.value);
    }
    event.stopPropagation();
  };
  return (
    <TextAreaWrapper style={style} className={className}>
      <TextArea
        value={value}
        required={isRequired}
        placeholder={placeholder ?? "Type your answer here"}
        onChange={_onChange}
        onFocus={_onFocus}
        rows={rows}
        height={height}
        cols="65"
      />
      {isRequired && showValidation && (
        <RequiredMessage
          message={
            isNonEmpty(name) ? `${REQUIRED_MESSAGE_PREFIX} ${name}` : null
          }
        />
      )}
    </TextAreaWrapper>
  );
}

export default InputTextArea;

const TextAreaWrapper = styled.div.attrs({
  className: "fs-exclude",
})``;

const TextArea = styled.textarea`
  font-style: normal;
  font-weight: ${FontWeights.normal};
  font-size: ${FontSizes.md};
  line-height: 24px;
  padding: 16px;
  resize: none;
  color: ${Colors.almostBlack};
  font-family: ${FontFamilies.base};
  width: 100%;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    width: 100%;
    height: ${(props) => props.height};
  }
`;
