import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Breakpoints } from "../../../styles/styleConstants";
import { HEADER_LEVEL } from "../../../utils/commonConst";
import HeaderText from "../../common/HeaderText";
import Image from "../../common/Image";

export default function AIHumanSecret(props) {
  const { headerText, showSubText, style, className } = props ?? {};
  return (
    <AIHumanSecretWrapper style={style} className={className}>
      <Container>
        <AIHumanSecretBody>
          <AIHumanSecretHeader>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_1}
              style={{
                lineHeight: "57.2px",
                fontWeight: "700",
                overflowWrap: "anywhere",
              }}
            >
              {headerText}
            </HeaderText>
            {showSubText ? (
              <HeaderText level={HEADER_LEVEL.LEVEL_4_1}>
                Automated to keep costs down. Human to be authentic and
                personalized.
              </HeaderText>
            ) : null}
          </AIHumanSecretHeader>
          <ImageWrapper>
            <Image
              src="partner/img-productscreenshot.png"
              className="ai-human-image"
              imgStyle={{
                objectFit: "contain",
              }}
            />
            <Image
              src="partner/img-ai+human.png"
              className="ai-human-image"
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </ImageWrapper>
        </AIHumanSecretBody>
      </Container>
    </AIHumanSecretWrapper>
  );
}

const AIHumanSecretWrapper = styled.div`
  padding: 96px 0;

  @media (max-width: ${Breakpoints.sm}) {
    padding: 60px 0;
  }
`;

const AIHumanSecretHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

const AIHumanSecretBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
  .ai-human-image {
    width: 600px;
    @media (max-width: ${Breakpoints.sm}) {
      width: 400px;
    }
  }
`;
